<template>
  <header id="header">   
    <div class="menu_header" id="mh1">
      <div class="top_background">
        <el-row type="flex" justify="center">
          <el-col :md="20" :lg="18" :xl="15" class="top_background">
              <div class="top">
                <div class="clinic">
                  <el-link :underline="false" href="https://www.tenyukai-hope.com/">
                    <div class="clinic-wrapper">
                        <div class="logo">
                          <img src="../assets/logo/hope_36x50@2x.png" alt="Hope" width="36px">
                        </div>
                      <div class="name">
                        <el-link :underline="false" href="https://www.tenyukai-hope.com/">
                          <div class="name-tenyukai">医療法人社団 天裕会</div>
                          <div class="name-nozomi">のぞみクリニック{{ this.clinicName }}</div>
                        </el-link>
                      </div>
                    </div>
                  </el-link>
                </div>
              </div>
              <div class="top_button" @click="goTo('/access')" style="right: 0px;">
                アクセス
              </div>
              <div class="top_button" @click="goTo('/department/telemedicine')" style="right: 150px;">
                WEB予約
              </div>
          </el-col>
        </el-row>
      </div>
          <!--<div class="covid">-->
          <!-- <div class="btn" @click="goHref('http://covid.rapha99.jp/page99')">コロナワクチン接種受付(個人)</div>-->
          <!-- <div class="btn" @click="goHref('http://covid.rapha99.jp')">コロナワクチン接種受付(集団)</div>-->
          <!--</div>-->

      <nav class="link_background">

            <ul class="link" style="margin: 0 auto;">
              <li @mouseover="hoverPcrCN = true" @mouseout="hoverPcrCN = false" class="link_box" @click.prevent="goTo('/')">              
                <el-link :underline="false" href="https://www.tenyukai-hope.com/" >
                  <span>ホーム</span>
                </el-link>
              </li>
              <li @mouseover="hoverInformation = true" @mouseout="hoverInformation = false" class="link_box" @click.prevent="goTo('/information')">
                <el-link :underline="false" href="https://www.tenyukai-hope.com/information" >
                  <span>お知らせ</span>
                </el-link>
              </li>
              
              <li @mouseover="hoverAccess = true" @mouseout="hoverAccess = false" class="link_box link_box_expand">
                <div class="el-link" :underline="false" @click="">
                  <span>診療一覧</span>
                </div>
                <div>
                  <div @mouseover="hoverAccess = true" @mouseout="hoverAccess = false" class="sub_box"  @click.prevent="goTo('/department/generalMedicine')">
                    <el-link href="https://tenyukai-hope.com/department/generalMedicine" :underline="false">
                      <span>一般内科</span>
                    </el-link>
                  </div>
                  <div @mouseover="hoverAccess = true" @mouseout="hoverAccess = false" class="sub_box"  @click.prevent="goTo('/department/fever')">
                    <el-link href="https://tenyukai-hope.com/department/fever" :underline="false">
                      <span>発熱外来</span>
                    </el-link>
                  </div>
                  <div @mouseover="hoverAccess = true" @mouseout="hoverAccess = false" class="sub_box" @click.prevent="goTo('/department/corona')">
                    <el-link href="https://tenyukai-hope.com/department/corona" :underline="false" >
                      <span>コロナ予防接種</span>
                    </el-link>
                  </div>
                  <div @mouseover="hoverAccess = true" @mouseout="hoverAccess = false" class="sub_box" @click.prevent="goTo('/department/diet')">
                    <el-link href="https://tenyukai-hope.com/department/diet" :underline="false" >
                      <span>メディカルダイエット</span>
                    </el-link>
                  </div>
                  <div @mouseover="hoverAccess = true" @mouseout="hoverAccess = false" class="sub_box" @click.prevent="goTo('/department/placenta')">
                    <el-link href="https://tenyukai-hope.com/department/placenta" :underline="false" >
                      <span>プラセンタ</span>
                    </el-link>
                  </div>
                  <div @mouseover="hoverAccess = true" @mouseout="hoverAccess = false" class="sub_box" @click.prevent="goTo('/department/stemsup')">
                    <el-link href="https://tenyukai-hope.com/department/stemsup" :underline="false" >
                      <span>エクソソーム点滴</span>
                    </el-link>
                  </div>
                </div>
              </li>
              <li @mouseover="hoverAbout = true" @mouseout="hoverAbout = false" class="link_box" @click.prevent="goTo('/about')">
                <el-link href="https://tenyukai-hope.com/about" :underline="false" >
                  <span>当院について</span>
                </el-link>
              </li>
              <li @mouseover="hoverAccess = true" @mouseout="hoverAccess = false" class="link_box" @click.prevent="goTo('/department/cancer')">
                <el-link href="https://tenyukai-hope.com/department/cancer" :underline="false" >
                  <span>がん治療</span>
                </el-link>
              </li>              
              <li @mouseover="hoverSending = true" @mouseout="hoverSending = false" class="link_box" @click.prevent="goTo('/sending')">
                <el-link href="https://tenyukai-hope.com/sending" :underline="false" >
                  <span>お問い合わせ</span>
                </el-link>
              </li>
            </ul>

      </nav>
    </div>
    <div class="header_space"></div>
  </header>
</template>

<script>
import ld from "lodash";
export default {
  name: "PageHeader",
  data() {
    return {
      hoverPcr: '',
      hoverPcrCN: '',
      hoverInformation: '',
      hoverAbout: '',
      hoverAccess: '',
      hoverQuestion: '',
      hoverSending: '',
      scroll_factor: 0,
      window_last_loc: 0
    }
  },
  props: {
    clinicName: {
      default: '築地'
    },
    pathName: {
      default: 'tsukiji'
    }
  },
  methods: {
    goTo(path) {
      this.$router.push(path)
    },
    goHref(path) {
      window.location.href = path;
    },
    headerLoc(){
      if(this.window_last_loc > window.scrollY){
        this.scroll_factor = 0;
        document.getElementById('mh1').style.top = 0+'px';
      } 
      else if(this.scroll_factor > -3){
        this.scroll_factor -= 1;
        if(this.scroll_factor <= -3){
          document.getElementById('mh1').style.top = "-"+140+'px';
        }
      }
      //console.log(this.scroll_factor)
      this.window_last_loc = window.scrollY;
    },
  },
  created() {
    this.window_last_loc = window.scrollY;
    window.addEventListener('scroll',ld.throttle(this.headerLoc,100))
  },
}
</script>



<style scoped>
/* ロゴ */
.top {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 15px;
  width: 928px;
}
.clinic-wrapper {
  align-items: center;
  cursor: pointer;
  display: flex;
}
.clinic .name {
  margin-left: 14px;
}
.clinic .name-tenyukai {
  color: #9E9E9E;
  font-size: 14px;
  font-weight: bold;
}
.clinic .name-nozomi {
  color: #62B6E2;
  font-size: 24px;
  font-weight: bold;
}
/* メニュー */
.link {
  display: flex;
  justify-content: center;
  width: fit-content;
  margin: 0 0;
  height:50px;
  border-left-color: #ffffff;
  border-left-style: solid;
  border-right-color: #ffffff;
  border-right-style: solid;
  border-width:2px;
  width: 100%;
  max-width: 1284px;
  background-color: #ffffff;
  
}
.link /deep/ .el-link span:first-child {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}
.link a{
  transition:150ms;
}

.link .a_tag{
  pointer-events: none;
  display: none;
}
.link a:hover {
  background-color: #ffffff;
  color: #62b6e2;
}
.link .el-link:hover{
  background-color: #ffffff;
  color: #62b6e2;
}
.link .el-link {
  color: #ffffff;
  height:50px;
  width:100%;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 2px;
  vertical-align:middle;
  background-color: #62b6e2;
}
.topCol{
  width:fit-content;
}
.link_box{
margin-left: 0.1%;
margin-right: 0.1%;
width: 16.66666%;
height:50px;

}

.link_box_expand{
overflow: hidden;
transition: 300ms;
}

.link_box_expand:hover{
height:356px;
}

.sub_box{
border-width:1px;
height:50px;
width: 100%;

}

.link_background{
  width: 100%;
  height:fit-content;
  background-color: #62b6e2;
  
}

.menu_header{
  position:fixed;
  left: 0px;
  top:0px;
  z-index: 1024;
  width: 100%;
  height: 138px;
  transition: 600ms;
}

.top_background{
  background-color: #ffffff;
  max-width: 1284px;
  width: 100%;
  margin: 0 auto;
  position: relative; 
}

.header_space{
  height:160px;
  width:100%;
}

.top_button{
  height: 36%;
  width: 20px;

  bottom:20%;
  width: 140px;
  border-radius: 8px;
  background-color: #f37878;
  border:#f37878 1px solid;
  color: #FFFFFF;
  position: absolute;
  right: 30px;
  cursor:pointer;
  font-size: 15px;
  text-align: center;
  line-height: 230%;
  transition: 150ms;
}

.top_button:hover{
  background-color:  #ffffff;
  color:  #f37878;
}


</style>
